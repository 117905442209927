import * as HostBrowserApis from '~/utils/host-browser-apis'

function setProfileProperty(propertyName: string, propertyValue: any) {
  HostBrowserApis.setProfileProperty(propertyName, propertyValue)
}

function getNtpUrl() {
  return 'https://' + location.hostname + location.pathname
}

async function getUsersNtpUrl() {
  const browserConfig = await HostBrowserApis.getConfig()
  return browserConfig?.new_tab_url || null
}

const incrementProfileProperty = HostBrowserApis.incrementProfileProperty

export { setProfileProperty, incrementProfileProperty, getUsersNtpUrl, getNtpUrl }
